// REACT
import React from "react"
// MUI
import { Typography } from "@material-ui/core"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// COMPONENTS
import Layout from "../components/layout"
import { PageTitle } from "../components/pageTitle"
import { ImageSlider } from "../components/imageSlider"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const BoatStoragePage = () => {
  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      wellfleetBoatStorage: file(
        relativePath: { eq: "wellfleet-boat-storage.jpg" }
      ) {
        ...BsmFluidImage
      }
      outboardMotorStorage: file(
        relativePath: { eq: "outboard-motor-storage.jpg" }
      ) {
        ...BsmFluidImage
      }
      dryBoatStorage: file(relativePath: { eq: "dry-boat-storage.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  const imageSteps = [
    {
      label: "Wellfleet Boat Storage",
      imgPath: data.wellfleetBoatStorage.childImageSharp.fluid.src,
    },
    {
      label: "Outboard Motor Storage",
      imgPath: data.outboardMotorStorage.childImageSharp.fluid.src,
    },
    {
      label: "Dry Boat Storage",
      imgPath: data.dryBoatStorage.childImageSharp.fluid.src,
    },
  ]

  // COMPONENT
  return (
    <Layout title="Boat Storage">
      <PageTitle title="BOAT STORAGE" />

      <Typography paragraph>
        Bay Sails Marine owns a dry storage yard on Route 6 in Wellfleet. You
        can store your boat there for the summer, the winter, year round, or on
        a transient basis. We can put your boat up on stands or you can store it
        on your own trailer. You can also store just your trailer while your
        boat is in the water. In addition, we have outboard motor storage
        available at our service shop. Please{" "}
        <Link to="/contact">contact us</Link> for more information and a storage
        contract.
      </Typography>

      <ImageSlider steps={imageSteps} maxWidth={600} />
    </Layout>
  )
}

export default BoatStoragePage
